define("ember-svg-jar/inlined/ibm-arrows-horizontal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M11.41 26.59L7.83 23H28v-2H7.83l3.58-3.59L10 16l-6 6 6 6 1.41-1.41zM28 10l-6-6-1.41 1.41L24.17 9H4v2h20.17l-3.58 3.59L22 16l6-6z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 32 32",
      "fill": "currentColor"
    }
  };
});