define("ember-svg-jar/inlined/investment-cashflow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M16 27c-3.6 0-7.1-1.8-9.2-5H12v-2H4v8h2v-3.7c2.5 3 6.1 4.7 10 4.7v-2zm4-17h5.2C21.9 4.9 15.1 3.5 10 6.8c-3.1 2-5 5.5-5 9.2H3C3 8.8 8.8 3 16 3c3.9 0 7.5 1.7 10 4.7V4h2v8h-8v-2z\"/><path fill-rule=\"evenodd\" d=\"M23.5 28a6.5 6.5 0 100-13 6.5 6.5 0 000 13zm0-1.393a5.107 5.107 0 100-10.214 5.107 5.107 0 000 10.214z\" clip-rule=\"evenodd\"/><path d=\"M25.465 20.161v-.819H24.1V18.25h-1.092v1.092h-.6a.785.785 0 00-.541.214.711.711 0 00-.224.514v1.092c0 .193.08.378.224.515a.785.785 0 00.54.213h2.109v1.092h-2.873v.729h1.365v1.092H24.1V23.71h.6a.785.785 0 00.541-.213.712.712 0 00.224-.515V21.89a.712.712 0 00-.224-.514.785.785 0 00-.54-.214h-2.189v-1h2.953z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "fill": "currentColor",
      "viewBox": "0 0 32 32"
    }
  };
});